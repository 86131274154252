import { render, staticRenderFns } from "./ListValidation.vue?vue&type=template&id=6844924a&scoped=true&"
import script from "./ListValidation.vue?vue&type=script&lang=js&"
export * from "./ListValidation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6844924a",
  null
  
)

export default component.exports