import gql from 'graphql-tag'


export const LIST_ROLE = (templateType) => gql`query LIST_ROLE ($roleType: String!, $q: FilterInput) {
  roles: listRole${templateType} (roleType: $roleType, q: $q) {
    id code name
  }
}`

export const DETAIL_ROLE = (templateType) => gql`query DETAIL_ROLE ($roleType: String!, $roleId: Int!) {
  role: detailRole${templateType} (roleType: $roleType, roleId: $roleId) {
    id code name
  }
}`

export const CREATE_ROLE = (templateType) => gql`mutation CREATE_ROLE ($roleType: String!, $input: Role${templateType}Input!) {
  createRole: createRole${templateType} (roleType: $roleType, input: $input) {
    id code name
  }
}`

export const UPDATE_ROLE = (templateType) => gql`mutation UPDATE_ROLE ($roleType: String!, $roleId: Int!, $input: Role${templateType}Input!) {
  updateRole: updateRole${templateType} (roleType: $roleType, roleId: $roleId, input: $input) {
    id code name
  }
}`

export const DESTROY_ROLE = (templateType) => gql`mutation DESTROY_ROLE ($roleType: String!, $roleId: Int!) {
  destroyRole: destroyRole${templateType} (roleType: $roleType, roleId: $roleId) {id}
}`

export const LIST_VALIDATION = (templateType) => gql`query LIST_VALIDATION ($roleType: String!) {
  roles: listRole${templateType}Validation (roleType: $roleType) {
    add {
      id code name
    }
    update {
      id code name
    }
    delete {
      id code name
    }
  }
}`
