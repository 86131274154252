<template>
  <sgv-table
    :items="roles"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td
          v-if="hidden.code"
          @click="toDetail(item.id)"
          class="pointer">
          {{ item.code }}
        </td>
        <td v-if="hidden.name">{{ item.name }}</td>
      </tr>
    </template>

    <button
      v-if="$auth.hasRole(`role:${roleType}:add`)"
      type="button"
      class="btn btn-link text-success"
      slot="action" @click="toDetail(0)">
      เพิ่ม
    </button>

  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_ROLE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    roleType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Role${this.$form.capitalize(this.roleType)}Detail`,
      rKey: `Role${this.$form.capitalize(this.roleType)}ListTable`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', filter: true},
        {text: 'ชื่อ', value: 'name', filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      roles: [],
    }
  },
  apollo: {
    roles: {
      query () {
        return LIST_ROLE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          roleType: this.roleType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      return filter
    },
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {roleId: id}
      })
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
